import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Bem-vindo à <span className="purple">Divsoft</span>, uma empresa de tecnologia localizada em <span className="purple">Divinópolis, MG</span>, onde a inovação é o nosso lema e a excelência é a nossa norma.
            <br />
            <br />
            Na Divsoft, nos dedicamos a explorar os limites da tecnologia para criar soluções que impulsionam o futuro. Nossa equipe de especialistas em desenvolvimento de software está empenhada em entregar produtos de alta qualidade que atendam às necessidades do mercado.
            <br />
            <br />
            Além de desenvolver software de ponta, nossa equipe também é apaixonada por outras atividades, incluindo:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Pesquisa e Desenvolvimento de Novas Tecnologias
            </li>
            <li className="about-activity">
              <ImPointRight /> Participação em Eventos e Conferências de Tecnologia
            </li>
            <li className="about-activity">
              <ImPointRight /> Contribuição para Projetos de Código Aberto
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Nosso objetivo é criar tecnologia que transforme o mundo!"{" "}
          </p>
          <footer className="blockquote-footer">Divsoft</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
