import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Como <span className="purple">trabalhamos?</span>
            </h1>
            <p className="home-about-body">
              Na Divsoft, temos uma abordagem única para o desenvolvimento de software que combina inovação, excelência técnica e foco no cliente.
              <br />
              <br />
              Nossa metodologia é baseada em princípios ágeis e práticas modernas de engenharia de software. Valorizamos a comunicação transparente, colaboração e entrega contínua.
              <br />
              <br />
              Ao escolher a Divsoft, você pode esperar soluções de software personalizadas e inovadoras para impulsionar o seu negócio. Garantimos resultados de alta qualidade e valor agregado, com comunicação transparente, entrega pontual e um compromisso inabalável com a sua satisfação.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <p>
              Conecte-se conosco através das redes sociais
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61558619120889"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/div-soft-339788303/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/divsoftmg/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
